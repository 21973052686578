<template>
  <div class="menu-list">
    <!-- 豆腐块 -->
    <section class="tops">
      <template v-for="item in topOpt">
        <li :class="[params.menuType == item.value ? 'top-item-active' : 'top-item']" @click="onTop(item)">
          <span>{{ item.label }}</span>
        </li>
      </template>
    </section>
    <!-- 菜单列表 -->
    <section class="table">
      <section class="table-top">
        <div class="table-top-left">
          <!-- <el-input prefix-icon="el-icon-search" v-model="kw" @input="onKeyWord" placeholder="搜索菜单名称" clearable>
          </el-input> -->
        </div>
        <div class="table-top-right">
          <el-button type="primary" @click="onAddOne()">新增一级菜单</el-button>
        </div>
      </section>
      <el-table :data="menuList" row-key="funId" border stripe :header-cell-style="{
        background: '#FAFAFA', color: '#000000'
      }" :tree-props="{ children: 'functionVos' }">
        <el-table-column prop="functionName" label="菜单名称" width="180">
        </el-table-column>
        <el-table-column prop="sortWeight" label="排序" width="180" align="center">
        </el-table-column>
        <el-table-column prop="requestPath" label="路由路径" width="180">
        </el-table-column>
        <el-table-column prop="icon" label="图标" align="center">
          <template slot-scope="scope">
            <el-image class="icon" v-if="scope.row.iconUrl" :src="scope.row.iconUrl"
              :preview-src-list="[scope.row.iconUrl]"></el-image>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="funCode" label="权限标识" width="180" align="center">
        </el-table-column>
        <el-table-column label="菜单层级" align="center" width="180">
          <template slot-scope="scope">
            <span :style="{ 'color': menuLevelColor[scope.row.menuLevel] }">{{ menuLevelObj[scope.row.menuLevel] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="onAddTwo(scope.row)" type="text" size="medium" v-if="scope.row.menuLevel < 3"
              v-has="'setting_menu_add'">新增</el-button>
            <el-button @click="onEdit(scope.row)" type="text" size="medium" v-has="'setting_menu_edit'">编辑</el-button>
            <el-button @click="onDel(scope.row)" type="text" size="medium" v-has="'setting_menu_del'">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <!-- 菜单编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" width="25%">
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="菜单名称" prop="functionName">
            <el-input v-model.trim="params.functionName" maxlength="8" placeholder="请填写菜单名称"></el-input>
          </el-form-item>
          <el-form-item label="排列序号" prop="sortWeight">
            <el-input v-model.number="params.sortWeight" maxlength="3" placeholder="请填写排列序号"></el-input>
          </el-form-item>
          <el-form-item label="路由路径" prop="requestPath"
            v-if="(btnLevel == 0 && btnType == 1) || (btnLevel == 1 && btnType == 2) || (btnLevel == 1 && btnType == 3) || (btnLevel == 2 && btnType == 3)">
            <el-input v-model.trim="params.requestPath" placeholder="请填写路由路径"></el-input>
          </el-form-item>
          <el-form-item label="权限标识" prop="icon"
            v-if="(btnLevel == 2 && btnType == 2) || (btnLevel == 3 && btnType == 3)">
            <el-input v-model.trim="params.funCode" :disabled="editTitle == '编辑菜单'" placeholder="请填写菜单标识"></el-input>
          </el-form-item>
          <!-- 菜单图标 -->
          <el-form-item label="菜单图标" v-if="(btnLevel == 0 && btnType == 1) || (btnLevel == 1 && btnType == 3)">
            <div class="upload-btn" @click="onUpload()">
              <img v-if="params.iconUrl" :src="params.iconUrl" class="upload-img">
              <i v-else class="el-icon-plus uploader-icon"></i>
            </div>
          </el-form-item>
        </section>
        <section class="form-foot">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>
<script>
import {
  getAllMenu,
  getAddMenu,
  getEditMenu,
  getDelMenu
} from "@/api/setting/menu";
import {
  getFileList
} from "@/api/common";
import { menuLevelObj, menuLevelColor } from "@/db/objs";
import { rules } from "@/db/rules";
import UpLoad from "@/components/upload";
export default {
  components: { UpLoad },
  data() {
    return {
      btnType: "",//1.一级；2.二级三级；3.编辑
      btnLevel: "",//1.一级；2.二级三级；3.编辑
      menuLevelObj,
      menuLevelColor,
      rules,
      topOpt: [{
        value: "0",
        label: "门店管理系统",
      },
      {
        value: "1",
        label: "壹启智享商户端",
      }],
      menuList: [],//菜单列表
      kw: "",
      editShow: false, //弹框开关
      editTitle: "",
      params: {
        menuType: "0",//pc还是小程序
        funId: "",//菜单id
        functionName: "",//菜单名称
        requestPath: "",//路由路径
        icon: "",//菜单图标
        iconUrl: "",//菜单图标
        sortWeight: "",//排序
        funCode: "",//权限标识
      },
    }
  },
  created() {
    this.getAllMenu()
  },
  watch: {
    params: {
      deep: true,
      handler(val) {
        if (val.sortWeight < 0) {
          this.params.sortWeight = 0
        }
      },
    },
  },
  methods: {
    // 【请求】全部菜单列表
    getAllMenu() {
      let data = {};
      getAllMenu(data).then(res => {
        if (this.params.menuType == "0") {
          this.menuList = res.data.pc;
        } else {
          this.menuList = res.data.app;
        }
      })
    },

    // 【请求】新增菜单
    getAddMenu() {
      let data = this.params;
      getAddMenu(data).then(res => {
        this.editShow = false;
        if (res.isSuccess == "yes") {
          this.getAllMenu();
        }
      })
    },

    // 【请求】编辑菜单
    getEditMenu() {
      let data = this.params;
      getEditMenu(data).then(res => {
        this.editShow = false;
        if (res.isSuccess == "yes") {
          this.getAllMenu();
        }
      })
    },

    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds
      };
      getFileList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.params.icon = res.data[0].attId;
          this.params.iconUrl = res.data[0].url;
        }
      })
    },

    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload()
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.getFileList(fileIds);
    },

    // 【监听】豆腐块选择
    onTop(item) {
      this.params.menuType = item.value;
      this.getAllMenu();
    },

    // 【监听】搜索
    onKeyWord: _.debounce(() => {
      console.log(this.kw);
    }, 500),


    // 【监听】新增一级菜单
    onAddOne() {
      this.editShow = true;
      this.editTitle = "新增一级菜单";
      this.btnType = 1;
      this.btnLevel = 0;
      this.params = {
        menuType: this.params.menuType,//pc还是小程序
        functionName: "",//菜单名称
        requestPath: "",//路由路径
        icon: "",//菜单图标
        sortWeight: "",//排序
        funId: "",//菜单id
        funCode: "",//权限标识
      }
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】新增二级菜单
    onAddTwo(row) {
      this.editShow = true;
      this.editTitle = "新增菜单";
      this.btnType = 2;
      this.btnLevel = row.menuLevel;
      this.params = {
        menuType: this.params.menuType,//pc还是小程序
        functionName: "",//菜单名称
        requestPath: "",//路由路径
        icon: "",//菜单图标
        sortWeight: "",//排序
        funId: row.funId,//菜单id
        funCode: "",//权限标识
      }
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑菜单
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑菜单";
      this.btnType = 3;
      this.btnLevel = row.menuLevel;
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】删除菜单
    onDel(row) {
      let data = {
        funId: row.funId
      }
      this.$confirm('你确定要删除该菜单吗?', '删除菜单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getDelMenu(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getAllMenu();
          }
        })
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.editTitle == "编辑菜单") {
            this.getEditMenu()
          } else {
            this.getAddMenu();
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.tops {
  display: flex;
  padding: .15rem;
  border-bottom: 1px solid #eee;
}

.top-item {
  width: 1.8rem;
  padding: .15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: .1rem;
  cursor: pointer;
}

.top-item-active {
  width: 1.8rem;
  padding: .15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: .1rem;
  cursor: pointer;
  // 不同部分
  border: 1px solid #1C83FF;
  background: #E6F2FF;
  color: #1C83FF;
}

.top-item:last-child {
  border: none;
}

.table {
  padding: .15rem;
}

.table-top {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}

.icon {
  width: .36rem;
  height: .36rem;
}

// 表单
.form-foot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.upload-btn {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}

.upload-btn:hover {
  border-color: #409EFF;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
}

.upload-img {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
}
</style>